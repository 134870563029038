import blogImg1 from '../../assets/blog01.png';
import blogImg2 from '../../assets/blog02.png';
import blogImg3 from '../../assets/blog03.png';
import blogImg4 from '../../assets/blog04.png';
import blogImg5 from '../../assets/blog05.png';


export default[
    {
        icon:blogImg1
    },
    {
        icon:blogImg2
    },
    {
        icon:blogImg3
    },
    {
        icon:blogImg4
    },
    {
        icon:blogImg5
    }
]
